<template>
  <b-modal
    id="action-user"
    @hidden="$emit('resetModal')"
    :title="selectedUser ? 'Update User' : 'Add User'"
    hide-footer
  >
    <div v-if="userRoles && userRoles.length">
      <FormulateForm
        name="adminAction"
        v-model="values"
        :schema="schema"
        @submit="submitHandler"
      >
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedUser"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedUser"> Update </span>
            <span v-else> Add </span>
            User</span
          >
        </FormulateInput>
      </FormulateForm>
    </div>
    <div v-else>
      <div class="text-center text-danger my-5">
        <b-spinner class="align-middle"></b-spinner><br />
        <span>Preparing form... </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getObjFromArray } from "@/utils/getObjFromArray";
import { user } from "@/config/api/users";
export default {
  props: ["userRoles", "selectedUser"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "select",
          name: "roles",
          label: "Roles",
          options: [],
          validation: "required",
        },
        {
          index: 2,
          type: "email",
          name: "email",
          label: "Email",
          validation: "required|email",
        },
        {
          index: 3,
          type: "password",
          name: "password",
          label: "Password",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedUser) {
        this.updateUser(data, this.selectedUser._id);
      } else {
        this.addUser(data);
      }
    },
    addUser(data) {
      this.performAction = true;
      const api = user.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("adminAction");
          this.$bvToast.toast("Admin added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Admin is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateUser(data, id) {
      this.performAction = true;
      const api = user.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Admin updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Admin is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    userRoles(val) {
      const index = getObjFromArray(this.schema, "roles").index;
      index
        ? val.forEach((item) => {
            this.schema[index].options.push({
              value: item._id,
              label: item.name,
            });
          })
        : "";
    },
    selectedUser(val) {
      if (val) {
        this.values = { ...val };
        this.values.roles = val.roles[0]._id;
        const index = getObjFromArray(this.schema, "password").index;
        this.schema[index].validation = null;
      }
    },
  },
};
</script>

<style></style>
